<template>
    <div class="integration mb-5" v-if="modelValue">
        <Form v-slot="{ errors }" @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" ref="coupon-form" class="w-100">
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Coupon Name (Just to identify it)</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.name }">
                        <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="ex: 100$ OFF" label="coupon name" rules="required" />
                    </div>
                    <ErrorMessage name="name" class="text-danger" />
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Coupon code (Must be at least 3 characters)</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.code }">
                        <Field type="text" name="code" v-model="form.code" placeholder="ex: AB10" label="coupon code" rules="required|min:3" />
                    </div>
                    <ErrorMessage name="code" class="text-danger" />
                </div>
            </div>
            <h3 class="sub_header">Status</h3>
            <div class="edit_section mt-2 mb-3">
                <label for="coupon_enabled" class="switch_option capsule_btn pb-4">
                    <h5>Coupon Enabled</h5>
                    <input type="radio" id="coupon_enabled" v-model="form.status" :value="1" hidden>
                    <div><span></span></div>
                </label>
                <label for="coupon_disabled" class="switch_option capsule_btn">
                    <h5>Coupon Disabled</h5>
                    <input type="radio" id="coupon_disabled" v-model="form.status" :value="0" hidden>
                    <div><span></span></div>
                </label>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Discount Type</label>
                    <div class="field_wpr has_prefix">
                        <multiselect
                            v-model="form.discount_type"
                            v-bind="discountTypes"
                            placeholder="Select Discount Type"
                        ></multiselect>
                        <span class="prefix"><i class="fas fa-tags"></i></span>
                    </div>
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Amount</label>
                    <div class="field_wpr has_prefix" :class="{ 'has-error': errors.discount_amount }">
                        <Field v-if="form.discount_type == 1" autocomplete="off" :onWheel="(ev) => ev.currentTarget.blur()"  type="number" name="discount_amount" min="0" v-model="form.amount" placeholder="0" :label="`discount ${form.discount_type ? 'percentage' : 'amount'}`" rules="required|min_value:0|max_value:100" />
                        <Field v-else autocomplete="off" type="number" :onWheel="(ev) => ev.currentTarget.blur()" name="discount_amount" min="0" v-model="form.amount" placeholder="0" :label="`discount ${form.discount_type ? 'percentage' : 'amount'}`" rules="required|min_value:0" />
                        <span class="prefix">{{ form.discount_type == 1 ? '%' : '$' }}</span>
                    </div>
                    <ErrorMessage name="discount_amount" class="text-danger" />
                </div>
            </div>
            <h3 class="sub_header">Valid Immediately</h3>
            <div class="edit_section mt-2 mb-5">
                <label for="coupon_valid_immediate" class="switch_option capsule_btn pb-4">
                    <h5>Yes</h5>
                    <input type="radio" id="coupon_valid_immediate" v-model="form.start_immediately" :value="1" hidden>
                    <div><span></span></div>
                </label>
                <label for="coupon_valid_set" class="switch_option capsule_btn">
                    <h5>On a set date</h5>
                    <input type="radio" id="coupon_valid_set" v-model="form.start_immediately" :value="0" hidden>
                    <div><span></span></div>
                </label>
                <div class="form_grp" v-if="!form.start_immediately">
                    <div class="group_item">
                        <label class="input_label">Set Date</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.start_date }">
                            <Field autocomplete="off" v-model="form.start_date" name="start_date" label="start date" rules="required">
                                <datepicker v-model="form.start_date" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                            </Field>
                        </div>
                        <ErrorMessage name="start_date" class="text-danger" />
                    </div>
                </div>
            </div>
            <h3 class="sub_header">This coupon is redeemable untill</h3>
            <div class="edit_section mt-2 mb-5">
                <label for="coupon_expire" class="switch_option capsule_btn pb-4">
                    <h5>Until I disable it</h5>
                    <input type="radio" id="coupon_expire" v-model="form.expiry_type" :value="0" hidden>
                    <div><span></span></div>
                </label>
                <label for="coupon_expire_set" class="switch_option capsule_btn">
                    <h5>Expire on a set date</h5>
                    <input type="radio" id="coupon_expire_set" v-model="form.expiry_type" :value="1" hidden>
                    <div><span></span></div>
                </label>
                <div class="form_grp" v-if="form.expiry_type">
                    <div class="group_item">
                        <label class="input_label">Set Expiry Date</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.expiry_date }">
                            <Field autocomplete="off" v-model="form.expiry_date" name="expiry_date" label="expiry date" rules="required">
                                <datepicker v-model="form.expiry_date" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                            </Field>
                            <ErrorMessage name="expiry_date" class="text-danger" />
                        </div>
                    </div>
                </div>
            </div>
            <h3 class="sub_header">Disable after how many uses?</h3>
            <div class="edit_section mt-2 mb-5">
                <label for="coupon-never-disable" class="switch_option capsule_btn">
                    <h5>Never disable</h5>
                    <input type="radio" id="coupon-never-disable" v-model="form.usage" :value="1" hidden>
                    <div><span></span></div>
                </label>
                <label for="coupon-single-use" class="switch_option capsule_btn pb-4">
                    <h5>Disable after 1 use</h5>
                    <input type="radio" id="coupon-single-use" v-model="form.usage" :value="2" hidden>
                    <div><span></span></div>
                </label>
                <label for="coupon-certain-date" class="switch_option capsule_btn pb-4">
                    <h5>Disable on a certain date</h5>
                    <input type="radio" id="coupon-certain-date" v-model="form.usage" :value="3" hidden>
                    <div><span></span></div>
                </label>
                <label for="coupon-client-usage" class="switch_option capsule_btn">
                    <h5>Disable for customer after they use it ({{ form.client_usage_limit }}) times</h5>
                    <input type="radio" id="coupon-client-usage" v-model="form.usage" :value="4" hidden>
                    <div><span></span></div>
                </label>
                <label for="coupon-all-usage" class="switch_option capsule_btn">
                    <h5>Disable for everyone after ({{ form.usage_limit }}) amount of total uses</h5>
                    <input type="radio" id="coupon-all-usage" v-model="form.usage" :value="5" hidden>
                    <div><span></span></div>
                </label>
                <div class="form_grp" v-if="form.usage == 3">
                    <div class="group_item">
                        <label class="input_label">Set Expiry Date</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.disable_date }">
                            <Field autocomplete="off" v-model="form.disable_date" name="disable_date" label="expiry date" rules="required">
                                <datepicker v-model="form.disable_date" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                            </Field>
                            <ErrorMessage name="disable_date" class="text-danger" />
                        </div>
                    </div>
                </div>
                <div class="form_grp" v-if="form.usage == 4">
                    <div class="group_item">
                        <label class="input_label">Set Amount of Uses</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.client_usage_limit }">
                            <Field autocomplete="off" type="number" name="usage_limit" v-model="form.client_usage_limit" placeholder="0" label="usage" rules="required|min_value:0" />
                        </div>
                        <ErrorMessage name="usage_limit" class="text-danger" />
                    </div>
                </div>
                <div class="form_grp" v-if="form.usage == 5">
                    <div class="group_item">
                        <label class="input_label">Set Amount of Uses</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.usage_limit }">
                            <Field autocomplete="off" type="number" name="usage_limit" v-model="form.usage_limit" placeholder="0" label="usage" rules="required|min_value:0" />
                        </div>
                        <ErrorMessage name="usage_limit" class="text-danger" />
                    </div>
                </div>
            </div>
            <div class="action_wpr">
                <button :disabled="couponLoader" type="button" class="btn cancel_btn" @click="closeForm()">Cancel</button>
                <button :disabled="couponLoader" class="btn save_btn">
                    <i class="fa fa-spin fa-spinner" v-if="couponLoader"></i>&nbsp; {{ couponLoader ? 'Saving' : 'Save' }}
                </button>
            </div>
        </Form>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Coupon Form',

        data () {
            return {
                form: {
                    product_id: 0,
                    name: '',
                    code: '',
                    status: 1,
                    discount_type: 1,
                    amount: 0,
                    start_immediately: 1,
                    start_date: '',
                    expiry_type: 0,
                    expiry_date: '',
                    usage: 1,
                    usage_limit: 0,
                },
                discountTypes: {
                    mode: 'single',
                    value: 'Flat Rate',
                    options: [
                        { value: 0, label: 'Flat Rate' },
                        { value: 1, label: 'Percentage' },
                    ]
                },
            };
        },

        props: {
            modelValue: Boolean,
            productId: Number,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.resetForm();
                }
            },
        },

        computed: mapState({
            couponLoader: state => state.checkoutModule.couponLoader,
        }),

        methods: {
            ...mapActions({
                createCoupon: 'checkoutModule/createCoupon',
                getCoupons: 'checkoutModule/getCoupons',
            }),

            resetForm () {
                const vm = this;

                vm.form = {
                    product_id: vm.productId,
                    name: '',
                    code: '',
                    status: 1,
                    discount_type: 1,
                    amount: 0,
                    start_immediately: 1,
                    start_date: '',
                    expiry_type: 0,
                    expiry_date: '',
                    usage: 1,
                    usage_limit: 0,
                };
            },

            closeForm () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmit (form, { setFieldError }) {
                const vm             = this;
                const params         = vm.form;
                params.product_id    = vm.productId;
                params.setFieldError = setFieldError;

                vm.createCoupon(params).then((result) => {
                    if (result) {
                        vm.getCoupons(vm.productId);
                        vm.closeForm();
                    }
                })
            },
        },
    };
</script>